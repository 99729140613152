import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { postCookieLaw, retrieveCookieLaw } from '../../redux/slice/cookie-law-slice';
import { RootState, useAppDispatch } from '../../redux/store';
import { useCookies } from 'react-cookie';

const CookiesComponent:React.FC = () => {
    const dispatch = useAppDispatch();
    const {CookieLawdata} = useSelector((state: RootState) => state.cookieLaw);
    const [isCookiesShow, setIsCookiesShow] = useState<boolean>(true);
    const [cookies, setCookie] = useCookies(['CMSCookieLevel']);
    //const [expires, setExpires] = useState<number>();

    let cookieAPI = CookieLawdata[0]?.result
    useEffect(() => {
        dispatch(retrieveCookieLaw())
     }, [dispatch])

     const isCookie = cookies.CMSCookieLevel;

   useEffect(() => {
    isCookie ? setIsCookiesShow(false) : setIsCookiesShow(true)

   }, [isCookie])


     

     const postCookie = () => {
       
            var expires = new Date(cookieAPI?.expiryDate);
        
            if(CookieLawdata){
                    setCookie('CMSCookieLevel', cookieAPI?.cookieLevel, { path: '/', secure: true, expires: expires } )
                    dispatch(postCookieLaw(Number(cookieAPI?.cookieLevel)))
                    setIsCookiesShow(false)
                }
        
     }

    return (
        <>
        {
            (isCookiesShow) ? (
                <div className="CookieConsent">
                <div className="container">
                    <span id="p_lt_ctl00_SimpleCookieLawConsent_lblText" className="ConsentText" dangerouslySetInnerHTML={{__html: cookieAPI?.levelText}}></span>
                    <span className="ConsentButtons">
                        {
                            (cookieAPI?.showDeny) ? <input type="button" className="ConsentButton btn btn-default" value={cookieAPI?.denyAllText} name={cookieAPI?.denyAllText} id={cookieAPI?.denyAllText} /> : '' 
                        }
                        {
                            (cookieAPI?.showSpecific) ? <input type="button" className="ConsentButton btn btn-default" value={cookieAPI?.allowSpecificText} name={cookieAPI?.allowSpecificText} id={cookieAPI?.allowSpecificText} /> : '' 
                        }
                        {
                            (cookieAPI?.showAll) ? <input type="button" className="ConsentButton btn btn-default" value={cookieAPI?.allowAllText} name={cookieAPI?.allowAllText} id={cookieAPI?.allowAllText} onClick={() => postCookie()} /> : '' 
                        }
                       
                    </span>
                </div>
            </div>
            ) : null
        }
           
            <a className="visuallyhidden focusable skip-link" href="#site-navigation">Skip to Main Navigation</a>
            <a className="visuallyhidden focusable skip-link" href="#site-main">Skip to Main Content</a>
            <a className="visuallyhidden focusable skip-link" href="#site-footer">Skip to Footer</a>
        </>
    )
}

export default CookiesComponent;