import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { RightSideTrailInitialStateType, RightSideTrailType } from "../../../models/right-side-trail-model";
import EventRightSideBooksService from '../../../services/right-side/event-right-side-books.service';

const initialState: RightSideTrailInitialStateType = {
    rightSideFeaturedBooksData: [],
    rightSideFeaturedLearningData: [],
    rightSideTrendingNewsData: [],
    rightSidePopularEventsData: [],
    Loading: true,
    Error: undefined,
};


export const retrieveEventRightSideBooks = createAsyncThunk('EventRightSideBooks/retrieve',
    async (eventPath: string, { rejectWithValue }) => {
        try {

            const response = await EventRightSideBooksService.getEventRightSideBooksService(eventPath)
            return [response.data]

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            return rejectWithValue(error.response.status)
        }
    }
)

const EventRightSideBooksSlice = createSlice({
    name: 'EventRightSideBooks',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveEventRightSideBooks.pending, (state) => {
                state.Loading = true;
            })
            .addCase(retrieveEventRightSideBooks.fulfilled, (state, action) => {
                if (action.payload) {
                    state.Loading = false;
                    state.rightSideFeaturedBooksData = action.payload as RightSideTrailType[];
                }
            })
            .addCase(retrieveEventRightSideBooks.rejected, (state, { payload }) => {
                state.Loading = false;
                state.Error = apiErrorHandler(Number(payload))
            })
    }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default EventRightSideBooksSlice.reducer;
