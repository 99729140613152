import React, { ReactNode } from "react";
import { Navigate } from 'react-router-dom';

const Convergence2023About = React.lazy(() => import('../pages/convergence-2023/convergence-2023-About'));
const Convergence2023AttendeeProfile = React.lazy(() => import('../pages/convergence-2023/convergence-2023-attendee-profile'));
const Convergence2023CodeOfConductIncidentReport = React.lazy(() => import('../pages/convergence-2023/convergence-2023-Code-of-Conduct-Incident-Report'));
const Convergence2023Committee = React.lazy(() => import('../pages/convergence-2023/convergence-2023-committee'));
const Convergence2023ExhibitorsSponsorships = React.lazy(() => import('../pages/convergence-2023/convergence-2023-exhibitors-sponsorships'));
const Convergence2023Home = React.lazy(() => import('../pages/convergence-2023/convergence-2023-home'));
const Convergence2023HotelTravel = React.lazy(() => import('../pages/convergence-2023/convergence-2023-hotel-travel'));
const Convergence2023Overview = React.lazy(() => import('../pages/convergence-2023/convergence-2023-overview'));
const Convergence2023Social = React.lazy(() => import('../pages/convergence-2023/convergence-social'));
const Convergence2023Register = React.lazy(() => import('../pages/convergence-2023/convergence-2023-register'));
const Convergence2023Schedule = React.lazy(() => import('../pages/convergence-2023/convergence-2023-schedule'));
const Convergence2023StudentProgram = React.lazy(() => import('../pages/convergence-2023/convergence-2023-Student-Program'));
const Convergence2023Recap = React.lazy(() => import('../pages/convergence-2023/convergence-recap'));
const Convergence2024Pricing = React.lazy(() => import('../pages/convergence-2023/convergence-2024-pricing'));
const Convergence2023Proposals = React.lazy(() => import('../pages/convergence-2023/convergence-call-for-proposals')); 

const PageNotFound = React.lazy(() => import("../pages/errors/page-not-found"));

export type route = {
    component: ReactNode;
    exact: boolean | undefined;
    name?: string | undefined;
    path: string;
    routes?: route[];
};

const Convergence2023: route[] = [
  { path: '/', exact: true, name: 'Convergence Home', component: <Navigate to={`/convergence-2023/recap${window.location.search}`} replace /> },
  { path: '/home', exact: true, name: 'Convergence Home', component: <Convergence2023Home /> },
  { path: '/about', exact: true, name: 'Convergence About', component: <Convergence2023About /> },
  { path: '/code-of-conduct-incident-report', exact: true, name: 'Convergence Code', component: <Convergence2023CodeOfConductIncidentReport /> },
  { path: '/committee', exact: true, name: 'Convergence Committee', component: <Convergence2023Committee /> },
  { path: '/attendee-profile', exact: true, name: 'Convergence Profile', component: <Convergence2023AttendeeProfile /> },
  { path: '/exhibitors-sponsorships', exact: true, name: 'Convergence Sponsorships', component: <Convergence2023ExhibitorsSponsorships /> },
  { path: '/travel', exact: true, name: 'Convergence Travel', component: <Convergence2023HotelTravel /> },
  { path: '/overview', exact: true, name: 'Convergence Overview', component: <Convergence2023Overview /> },
  { path: '/social', exact: true, name: 'Convergence Social', component: <Convergence2023Social /> },
  { path: '/register', exact: true, name: 'Convergence Register', component: <Convergence2023Register /> },
  { path: '/schedule', exact: true, name: 'Convergence Schedule', component: <Convergence2023Schedule /> },
  { path: '/student-program', exact: true, name: 'Convergence Student', component: <Convergence2023StudentProgram /> },
  { path: "/recap", exact: true, name: 'Convergence Recap', component: <Convergence2023Recap /> },
  { path: "/2024-pricing", exact: true, name: 'Convergence Pricing', component: <Convergence2024Pricing /> },
  { path: '/2024-call-for-proposals', exact: true, name: 'Convergence Call for Proposals', component: <Convergence2023Proposals /> },

  //alternate urls
  { path: '/hotel-travel', exact: true, name: 'Convergence Travel', component: <Convergence2023HotelTravel /> },
  { path: '/sponsorship', exact: true, name: 'Convergence Sponsorships', component: <Convergence2023ExhibitorsSponsorships /> },
  { path: '/schedule-at-a-glance', exact: true, component: <Navigate to={`/convergence-2023/schedule${window.location.search}`} replace /> },
  { path: '/convergence-committee', exact: true, component: <Navigate to={`/convergence-2023/committee${window.location.search}`} replace /> },
  //end of alternate urls

  { path: '/*', exact: true, component: <PageNotFound /> },
]
export default Convergence2023;
