import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { retrieveSiteMap } from "../redux/slice/sitemap-slice";
import { useAppDispatch } from "../redux/store";

const Sitemap = () => {

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(retrieveSiteMap())
    }, [dispatch])

  return <>
        <style>
            {`
                .loader-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100Vh;
                }
            `}
        </style>
        <Helmet>
        </Helmet>
        {/* {
            (SiteMapLoading) ? <LoadingComp /> : (
                <pre>{xmlData}</pre>
            )
        } */}
 
        </>
}

export default Sitemap
