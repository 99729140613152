import React from "react";
import {Navigate, useLocation} from "react-router-dom";

export const RemoveTrailingSlash = ({...rest}) => {
  const location = useLocation()

    // If the last character of the url is '/' or url contains uppercase characters
  if (location.pathname.match('/.*/$') || location.pathname.match(/[\/+]{2,}/g) || location.pathname.match(/^[A-Z]+$/)) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+/g, '/').replace(/\/+$/, "").toLowerCase(),
            search: location.search
        }}/>
    } else return null
}